
import { defineComponent, ref, watch, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiLogisticsServiceProvider } from "@/core/api";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-service-profile-modal",
  props: {
    id: { type: Number, default: 0 },
    merchantId: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addServiceProfileModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const merchantOptions = ref([]);
    const relationshipOptions = ref([]);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      logistics_service_provider_id: props.merchantId,
      name: "",
      maximum_weight: "",
      service_level: "",
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const rules = ref({
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      maximum_weight: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_level: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiLogisticsServiceProvider.showProfileShipment({ id: id })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              let year = {
                fiscal_year: String(data.data.fiscal_year),
              };
              formData.value = { ...data.data, ...year };
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const getRelationshipOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: "global_company_parent_relationship",
      });
      if (data.code == 0) {
        relationshipOptions.value = data.data.items;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getRelationshipOptions()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editRelationship();
          } else {
            addRelationship();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addRelationship = () => {
      ApiLogisticsServiceProvider.addProfileShipment(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addServiceProfileModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editRelationship = () => {
      ApiLogisticsServiceProvider.updateProfileShipment(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              // resetForm();
              hideModal(addServiceProfileModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addServiceProfileModalRef.value);
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    return {
      t,
      loading,
      merchantOptions,
      relationshipOptions,
      formData,
      rules,
      formRef,
      addServiceProfileModalRef,
      submitButton,
      getFormData,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
