
import { defineComponent, onMounted, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiLogisticsServiceProvider } from "@/core/api";
import AddServiceProfileModal from "@/views/organisation-and-facility/logistics-service-provider/AddServiceProfileModal.vue";
import mixin from "@/mixins";
// import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import moment from "moment";
import { useI18n } from "vue-i18n";
import { CommonIdType } from "@/core/directive/type/common";
import {
  commonBackToList,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "organisation-and-facility-logistics-service-provider-information",
  components: { AddServiceProfileModal },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      status: "",
      service_provider_id: "",
      service_provider_name: "",
      service_provider_short_key: "",
      service_provider_based_country: "",
      service_provider_coverage: "",
      service_provider_services: "",
      service_provider_customs_terms: "",
    });
    const options = ref({
      service_provider_based_country: [],
      service_provider_coverage: [],
      service_provider_services: [],
      service_provider_customs_terms: [],
    });

    const parentRelationshipId = ref<CommonIdType>(0);
    const merchantId = ref<CommonIdType>(route.params.id);
    const serviceProfileTableData = ref([]);

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_short_key: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_based_country: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_provider_coverage: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.service_provider_based_country = data.data;
      }
    };
    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "logistics_service_provider_coverage",
          "logistics_service_provider_services",
          "logistics_service_provider_customs_terms",
        ],
      });
      if (data.code == 0) {
        options.value.service_provider_coverage =
          data.data.logistics_service_provider_coverage.items;
        options.value.service_provider_customs_terms =
          data.data.logistics_service_provider_customs_terms.items;
        options.value.service_provider_services =
          data.data.logistics_service_provider_services.items;
      }
    };

    const getShowInfo = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.showLogisticsServiceProvider({
          id: route.params.id,
        });
      if (data.code == 0) {
        formData.value = data.data;
      }
    };

    const handleAddServiceProfile = () => {
      parentRelationshipId.value = 0;
    };
    const handleEditServiceProfile = (row) => {
      parentRelationshipId.value = row.id;
    };

    const getCustomerOptions = (query?: string, id?: string) => {
      // let d = id ? { id: id } : { search_value: query };
      // ApiBase.getUserSourceData(d)
      //   .then(({ data }) => {
      //     if (data.code == 0) {
      //       options.value.customer = data.data;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    };

    const updateParentServiceProfile = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.showProfileShipmentlist({
          logistics_service_provider_id: route.params.id,
        });
      if (data.code == 0) {
        // console.log(data.data);
        serviceProfileTableData.value = data.data.item;
      }
    };

    const handleDeleteServiceProfile = (index, row) => {
      Swal.fire({
        text: t("common.secondConfirmation"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } =
            await ApiLogisticsServiceProvider.deleteProfileShipment({
              id: row.id,
            });
          // loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: t("common.deletedSuccessfully"),
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateParentServiceProfile();
            });
          }
        }
      });
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([
        getShowInfo(),
        getCountryData(),
        getTaggingData(),
        updateParentServiceProfile(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiLogisticsServiceProvider.updateLogisticsServiceProvider(
            formData.value
          )
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(
        router,
        "/organisation-and-facility/logistics-service-provider"
      );
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });
    return {
      t,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      parentRelationshipId,
      merchantId,
      serviceProfileTableData,
      getFromInfo,
      submit,
      getCustomerOptions,
      handleAddServiceProfile,
      updateParentServiceProfile,
      handleEditServiceProfile,
      handleDeleteServiceProfile,
      backToList,
    };
  },
});
